

@font-face {
	font-family: 'Proxima Nova Condensed Regular';
	src: url('fonts/ProximaNovaCond-Regular/ProximaNovaCond-Regular.eot');
	src: url('fonts/ProximaNovaCond-Regular/ProximaNovaCond-Regular.eot?#iefix') format('embedded-opentype'),
     url('fonts/ProximaNovaCond-Regular/ProximaNovaCond-Regular.woff') format('woff'),
      url('fonts/ProximaNovaCond-Regular/ProximaNovaCond-Regular.ttf') format('truetype');
}

@font-face {
	font-family: 'Proxima Nova Condensed Bold';
	src: url('fonts/ProximaNovaCond-Bold/ProximaNovaCond-Bold.eot');
	src: url('fonts/ProximaNovaCond-Bold/ProximaNovaCond-Bold.eot?#iefix') format('embedded-opentype'),
     url('fonts/ProximaNovaCond-Bold/ProximaNovaCond-Bold.woff') format('woff'),
      url('fonts/ProximaNovaCond-Bold/ProximaNovaCond-Bold.ttf') format('truetype');
}

@font-face {
	font-family: 'GorgeousPixel';
	src: url('fonts/GorgeousPixel/GorgeousPixel.ttf');
}

@font-face {
	font-family: 'Proxima Nova Black';
	src: url('fonts/ProximaNova-Black/ProximaNova-Black.eot');
	src: url('fonts/ProximaNova-Black/ProximaNova-Black.eot?#iefix') format('embedded-opentype'),
     url('fonts/ProximaNova-Black/ProximaNova-Black.woff') format('woff'),
      url('fonts/ProximaNova-Black/ProximaNova-Black.ttf') format('truetype');
}

@font-face {
	font-family: 'Proxima Nova Extra Condensed Bold';
	src: url('fonts/ProximaNovaExCn-Bold/ProximaNovaExCn-Bold.eot');
	src: url('fonts/ProximaNovaExCn-Bold/ProximaNovaExCn-Bold.eot?#iefix') format('embedded-opentype'), url('fonts/ProximaNovaExCn-Bold/ProximaNovaExCn-Bold.woff') format('woff'), url('fonts/ProximaNovaExCn-Bold/ProximaNovaExCn-Bold.ttf') format('truetype');
}

body {
  
  margin: 0;
  color: white;
  font-family: 'Proxima Nova Condensed Bold';
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
 